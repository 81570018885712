<template>
  <b-modal
    id="create-modal"
    ref="create-modal"
    title="Thêm mới khách hàng vào danh sách tiếp nhận"
    size="xl"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    @cancel="resetModal"
    @hide="resetModal"
  >
    <form-create></form-create>
    <div>
      <p v-for="(item, index) in errorMessage" :key="index" style="color: #ff0000">{{ item }}</p>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancel"
          tabindex="0"
        >
          Thoát
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click.stop="handleSubmit"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('customerReceives');
export default {
  name: 'CreateModal',
  components: {
    'form-create': () => import('./FormCreate.vue'),
  },
  data() {
    return {
      loading: false,
      isChange: null,
      errorMessage: []
    };
  },
  methods: {
    ...mapActions(['resetListCustomer']),

    resetModal() {
      this.isChange = false;
    },

    async handleSubmit() {
      if(this.listCustomer.length === 0){
        this.$toastr.e({
          title: 'Lỗi !',
          msg: "Bạn cần phải input ít nhất 1 khách hàng",
        });
        return;
      }

      const finalList = [];

      for (const customer of this.listCustomer) {
        if (!this.validateCustomer(customer)) return;
        finalList.push({
          Name: customer.name,
          PhoneNumber: customer.phoneNumber,
          DiseaseType: customer.diseaseType.text,
          CourseId: customer.course.value,
          CoachId: customer.coach.value,
          ContactSource: customer.contactSource,
          BirthYear: customer.dateOfBirth,
        });
      }
      const listCustomerJson = JSON.stringify(finalList);

      try {
        const response = await this.$api.post(
          'CustomerReceives',
          listCustomerJson,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        if (response.data &&
            response.data.some((customer) => customer.errorMessage !== null)
        ) {
          this.errorMessage  = response.data
          .filter((customer) => customer.errorMessage !== null)
          .map((customer) => customer.errorMessage);
        } else {
          this.$toastr.s({
            title: 'Thành công !',
            msg: 'Thêm mới khách hàng trung tâm thành công.',
          });
          this.errorMessage = [];
          this.resetListCustomer();
          this.$emit('load-data');
          this.$bvModal.hide('create-modal');
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },

    validateCustomer(customer) {
      if (customer.name === '') {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Không được để trống tên khách hàng',
        });
        return false;
      }

      if (customer.phoneNumber === '') {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Không được để trống số điện thoại',
        });
        return false;
      }

      if (customer.diseaseType === '') {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Không được để trống phân loại',
        });
        return false;
      }

      return true;
    },

    async handleCancel() {
      this.resetListCustomer();
      this.errorMessage = []
      if (this.isChange) {
        let confirm = await this.handleConfirm();

        confirm == true ? this.$bvModal.hide('create-modal') : null;
      } else {
        this.$bvModal.hide('create-modal');
      }
    },
  },

  computed: {
    ...mapState(['listCustomer']),
  },
};
</script>